.profile-content {
  width: 100%;
  height: 100%;
  padding-top: 5%;

  .profile-form {
    max-width: 300px;
    margin-left: auto !important;
    margin-right: auto !important;

    &-button {
      width: 100%;

      &:not(:first-child) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .token {
    margin: 20px;
  }
}
