.taxonomy-rules-content {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 24px;

  &-title {
    font-size: 20px;
    font-weight: bold;
  }

  &-table {
    .rule-basis {
      width: 75px;

      &-title {
        margin-left: 6px;
      }
    }

    .rule-criteria-chip {
      padding: 3px;
      border: 2px solid #78c1fa;
      border-radius: 4px;
      font-size: 12px;
      color: #198be2;
      background: #d6e7f6;
      margin: 0 4px;
    }
  }

  &-edit {
    .rule-basis {
      &-title {
        margin-left: 6px;
      }
    }

    &-settings {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;

      .ant-radio-button-wrapper-checked {
        .rule-basis {
          color: white !important;

          &-title {
            color: white !important;
          }
        }

        &.basis-button-include {
          background: #2cae2e !important;
          border-color: #2cae2e !important;

          &:before {
            background: #2cae2e !important;
          }
        }

        &.basis-button-exclude {
          background: #ff1f2b !important;
          border-color: #ff1f2b !important;

          &:before {
            background: #ff1f2b !important;
          }
        }
      }

      .radio-group-container {
        width: 100%;
        display: flex;

        .radio-group-button {
          text-align: center;
          flex: 1;
        }
      }

      &-left {
        margin-right: 48px;
      }

      &-title {
        font-weight: bold;
      }

      &-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &-tags {
          display: table-caption;
          margin-bottom: 12px;

          .rule-tags-multi-select {
            width: 300px;
          }
        }
      }

      &-label {
        width: 60px;
      }
    }
  }
}
