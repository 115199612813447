.App {
  height: 100%;

  .ant-layout {
    height: calc(100vh - 48px);
  }
}

.page-content {
  width: 100%;
  height: 100%;
}

.capitalized {
  text-transform: capitalize;
}
