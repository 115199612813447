.properties-content {
  position: relative;
  padding-bottom: 48px;

  .ant-form-item-label {
    display: none;
  }

  &-title {
    padding: 24px;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-divider-with-text {
    text-transform: capitalize;

    &::before,
    &::after {
      border-top: 1px solid #dadada !important;
    }
  }

  .properties-add-actions {
    margin-left: 24px;
    display: flex;
    justify-content: space-between;

    &-button {
      margin-right: 16px;
    }
  }

  .properties-fields {
    padding: 0 24px;

    &-item {
      margin-bottom: 24px;
      padding: 7px;
      border-radius: 0;
      box-shadow: none;

      // &:hover {
      // }

      .ant-input-number {
        width: 100%;
      }

      .header {
        display: flex;
        justify-content: space-between;

        .labels {
          h4 {
            color: rgb(9, 14, 36);
          }

          h5 {
            color: rgb(98, 110, 153);
          }
        }
      }

      .input {
        flex: 1;
        &.error {
          box-shadow: 0 0 10px red;
        }

        &.success {
          box-shadow: 0 0 10px rgb(48, 181, 43);
        }
      }

      .readonly {
        .ant-input {
          color: #00000040;
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
        }
      }
    }
  }

  .properties-template-upload {
    display: flex;
    padding: 0;

    &-readonly {
      .ant-upload-list-item-actions {
        button {
          display: none;
        }
      }
    }
  }

  .properties-template-drag-drop {
    padding: 0 24px;

    &-content {
      display: flex;

      .ant-upload-drag {
        width: 300px;
        height: auto;
      }
    }

    &-add-tem {
      margin-top: 24px;
      margin-left: 120px;
    }
  }
}

.radioButtonIcon {
  max-width: 12px;
  margin-right: 7px;
}

.ant-radio-button-wrapper {
  white-space: nowrap;
  padding-right: 30px !important;
}

.copyEntitySettings {
  text-align: center;
  margin-top: 7px;
  margin-bottom: 14px;

  .title {
    font-weight: bold;
    font-size: 12pt;
  }
  .subtitle {
    display: block;
    font-weight: bolder;
  }
  .clientSelect {
    display: block;
    min-width: 150px;
    margin-bottom: 14px;
  }
  .entitiesSelect {
    min-width: 150px;
  }
}

