.info-card {
  .ant-card-head {
    padding-top: 12px;
    min-height: unset;
    border-bottom: 0;
  }

  .ant-card-head-title,
  .ant-card-extra {
    font-size: 14px;
    padding: 5px 0 0;
  }

  .ant-card-body {
    padding: 5px 24px;
  }

  .info-value {
    font-size: 25px;
    color: black;
  }

  .info-change {
    padding: 6px 0;
    font-size: 14px;
    height: 60px;
  }

  .info-card-footer {
    padding: 6px 0;
    border-top: 1px solid #f0f0f0;
    overflow: hidden;
  }
}
