.rules-content {
  height: 100%;

  .side-bar-content {
    border-right: 2px solid #dddddd;
    background: white !important;
    padding: 0;

    .ant-layout-sider-trigger {
      width: 23px !important;
      height: 23px;
      position: absolute;
      top: 24px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: white;
      color: #0f192e;
      border: 1px solid #dfd5d5;
      font-size: 12px;
    }

    .radio-group-container {
      width: 100%;
      display: flex;

      .radio-group-button {
        text-align: center;
        flex: 1;

        &-no-border {
          border: 0;
          outline: 0;
          box-shadow: none;

          &:before {
            display: none !important;
          }
        }
      }
    }

    .categories-tree-add-btn {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &-main {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 24px;
  }

  &-table {
    .rule-basis {
      width: 75px;

      &-title {
        margin-left: 6px;
      }
    }

    .rule-criteria-chip {
      padding: 3px;
      border: 2px solid #78c1fa;
      border-radius: 4px;
      font-size: 12px;
      color: #198be2;
      background: #d6e7f6;
      margin: 0 4px;
    }
  }

  &-edit {
    .rule-basis {
      &-title {
        margin-left: 6px;
      }
    }

    &-settings {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;

      .ant-radio-button-wrapper-checked {
        .rule-basis {
          color: white !important;

          &-title {
            color: white !important;
          }
        }

        &.basis-button-include {
          background: #2cae2e !important;
          border-color: #2cae2e !important;

          &:before {
            background: #2cae2e !important;
          }
        }

        &.basis-button-exclude {
          background: #ff1f2b !important;
          border-color: #ff1f2b !important;

          &:before {
            background: #ff1f2b !important;
          }
        }
      }

      .radio-group-container {
        min-width: 250px;
        width: 100%;
        display: flex;

        .radio-group-button {
          text-align: center;
          flex: 1;
        }
      }

      &-left {
        margin-right: 48px;
      }

      &-title {
        font-weight: bold;
      }

      &-save-btn {
        background: #2cae2e !important;
        border-color: #2cae2e !important;
      }

      &-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &-tags {
          display: table-caption;
          margin-bottom: 12px;

          .rule-tags-multi-select {
            min-width: 300px;
          }
        }
      }

      &-label {
        min-width: 120px;
      }
    }

    .spin-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.hidden-rule-settings {
  display: none;
}

.link {
  cursor: pointer;
}
