.taxonomy-products-content {
  position: relative;
  height: 100%;
  overflow: auto;

  &-title {
    padding: 24px;
    padding-bottom: 0;
    font-size: 20px;
    font-weight: bold;
  }

  .spin-wrapper {
    width: 100%;
    height: 100%;
  }
}
