.top-search .ant-table-wrapper {
  min-height: 300px;
}

.search-status-head {
  padding: 0 12px;
}

.search-status-head .search-status-head-title {
  margin-right: 6px;
}

.search-status-content {
  padding: 0 12px;
  display: flex;
  align-items: center;
}

.search-status-content .search-status-value {
  font-size: 30px;
  margin-right: 36px;
}
