.trend-ranking-content {
  padding: 0 32px 32px 72px;

  .ant-list-split {
    .ant-list-items {
      max-height: 250px;
      overflow: auto;
    }

    .ant-list-item,
    .ant-list-header {
      border-bottom: none;
      padding: 6px 0;
    }
  }
}
