.custom-pro-table {
  .ant-card {
    background: transparent;

    .ant-table-selection {
      width: 50px;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #d6d3d3;

      .ant-checkbox-wrapper {
        float: left;
        margin-left: 17px;
      }
    }

    .ant-pro-table-toolbar {
      overflow: auto;

      &-option {
        min-width: 470px;
      }
    }

    .ant-table {
      background: transparent;

      .table-expanded-row {
        padding-left: 60px;
      }
    }
  }

  &-no-selected {
    .ant-pro-table-alert {
      display: none;
    }
  }
}

.marketReachTable {
  .toolbar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 12px;
    .left {
      justify-content: flex-start;
    }
    .right {
      justify-content: flex-end;
    }
  }
}
