.main-content {
  background: #f0f2f5;
  padding: 24px;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;

  .row-content {
    margin: 0 -12px;
  }

  .custom-card-container {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
  }
}
