.trends-content {
  background: white;
  padding: 12px;
  margin-bottom: 24px;

  .date-range-select-buttons {
    margin-right: 24px;
  }
}

@media screen and (max-width: 992px) {
  .trends-content {
    .date-range-select-buttons {
      display: none;
    }
  }
}
