.categories-content {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  &-tree {
    &-container {
      width: 250px;
      height: 100%;
      padding: 12px;
      border-right: 2px solid #dddddd;
      overflow: auto;
    }

    &-title {
      font-weight: bold;
      margin: 12px 0;
    }

    &-content {
      margin-top: 12px !important;
      background: transparent !important;
    }
  }

  &-preview {
    height: 100%;

    &-container {
      width: calc(100% - 250px);
      height: 100%;
      flex: 1;
      padding: 12px;
      overflow: auto;
    }

    &-switch {
      margin-right: 12px;

      &-label {
        margin-right: 12px;
      }
    }

    &-products-table {
      width: 100%;
    }

    .spin-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}
