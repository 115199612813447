.payment-status-content {
  background: white;
  padding: 12px;

  .conversion-rate {
    width: 130px;
    height: 100px;
  }

  .conversion-rate-progress {
    margin-top: auto;
    margin-bottom: auto;
  }

  .conversion-rate-content {
    display: flex;
    flex-direction: column;
  }

  .store-name {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .conversion-rate-title {
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .conversion-rate-value {
    font-size: 24px;
    line-height: 32px;
  }
}
