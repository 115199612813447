.custom-gallery {
  width: 100%;
  max-height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  &-image {
    cursor: pointer;

    &-active {
      border: 2px solid #0091f4;
      border-radius: 12px;
    }
  }
}
