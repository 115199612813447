.products-table-container {
  height: 100%;
  padding: 24px;
  overflow: auto;

  .expand-products-table {
    .ant-pro-table-alert {
      display: none;
    }
    .ant-table-thead {
      display: none;
    }
    .ant-table-selection-column {
      padding-left: 83px !important;
      padding-right: 23px !important;
    }
  }
}

.products-image-view-content {
  height: 100%;
  overflow: auto;

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px !important;
    border-bottom: 2px solid #e8e8e8;
  }

  &-toolbar {
    display: flex;
    align-items: center !important;
    justify-content: flex-end;
    padding: 40px 36px 16px;
    border-bottom: 2px solid #e8e8e8;
    overflow: auto;

    &-basic {
      margin-left: 12px;
      min-width: 130px;

      &:first-child {
        margin-left: 32px;
      }

      &-item {
        font-size: 16px;
        margin-left: 16px;
      }
    }

    &-item {
      margin-left: 8px;

      &:first-child {
        margin-left: auto;
      }
    }
  }

  &-images {
    display: flex;
    height: 100%;

    &-details {
      width: 350px;
      height: 100%;
      overflow: auto;
      border-left: 2px solid #e8e8e8;

      &-item {
        min-height: 50px;
        display: flex;
        align-items: center;
        background: white;
        border-bottom: 2px solid #e8e8e8;
        padding: 0 12px;

        &-key {
          width: 150px;
          font-size: 14px;
        }

        &-value {
          flex: 1;
          font-size: 14px;
          word-break: break-all;

          &-value-title {
            font-weight: bold;
          }
        }
      }
    }

    &-carousel {
      flex: 1;
    }
  }
}

.product-detail-header {
  display: flex;
  justify-content: space-between;
  padding-right: 36px;

  &-title {
    line-height: 32px;
  }
}

.products-result-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  font-size: 14px;

  .anticon {
    margin: 0 24px;
  }
}
