.menuBar {
  display: flex;
  height: 48px;
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: hidden;
  box-shadow: 0 0 30px #f3f1f1;
  background: #ffffff;

  .logo {
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: black;
    font-size: 18px;
  }

  .leftMenu {
    margin-left: 24px;
    flex: 1;

    .ant-menu {
      border: 0;
      background: white;

      .ant-menu-submenu {
        height: 100%;
      }

      .ant-menu-item {
        top: 2px;
        line-height: 42px;
        color: #717171;
        padding: 0 15px;

        &:hover {
          border-color: white;
        }
      }

      .ant-menu-horizontal {
        border-bottom: 0;
      }
    }
  }

  .menuCon {
    height: 100%;

    .ant-menu-item {
      height: 100%;
      padding: 0 5px;

      a {
        padding: 10px 15px;
      }
    }

    .ant-menu-submenu-title {
      padding: 10px 20px;

      a {
        padding: 10px 15px;
      }
    }

    .ant-menu-horizontal {
      height: 100%;
      line-height: 42px;
      border-bottom: none;
    }

    .rightMenu {
      height: 100%;
      float: right;

      .loggedIn {
        height: 100%;
        display: flex;
        align-items: center;

        .client-select {
          margin: 0 12px;
        }
      }
    }
  }

  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 16px;
    display: none !important;
    background: none;
  }

  .barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: #1890ff;
    position: relative;
    margin-bottom: 4px;
  }

  .barsBtn:after,
  .barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: #1890ff;
  }

  .barsBtn:after {
    top: auto;
    bottom: -6px;
  }
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block !important;
  }

  .logo a {
    margin-left: -20px;
    padding: 10px 20px;
  }

  .menuCon {
    .ant-menu-item,
    .menuCon .ant-menu-submenu-title {
      padding: 1px 0;
    }
  }
}
