.custom-menu {
  .ant-btn-icon-only {
    border: 0;
    box-shadow: none;

    &:after {
      display: none;
    }
  }
}
